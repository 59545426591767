import classNames from "classnames";
import React, { useMemo } from "react";
import LuceIcon from "../../images/card-ele.png";
import GasIcon from "../../images/card-gas.png";

const ConfigurazioneCard = ({
  products,
  chosenProd,
  setChosenProd,
  selectActive,
  setSelectActive,
  commodity,
  selectedCard,
  setSelectedCard,
  ctaFlussoAction,
  offertaTitle,
  ctaList,
}) => {
  const eleFeaturesSw11 = useMemo(() => {
    const features = products?.[0]?.content?.switchTitle1?.content?.caratt1Ele
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Ele?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoEnergia
              ? products[0].content.prodottoCRM.corrispettivoEnergia
              : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF1
              ? products[0].content.prodottoCRM.corrispettivoF1
              : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF2
              ? products[0].content.prodottoCRM.corrispettivoF2
              : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF3
              ? products[0].content.prodottoCRM.corrispettivoF3
              : ""
          )
          ?.replace(
            /@spreadLuce/g,
            products?.[0]?.content?.prodottoCRM?.spreadLuce
              ? products[0].content.prodottoCRM.spreadLuce
              : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            products?.[0]?.content?.prodottoCRM?.spreadLuceLordoPerdite
              ? products[0].content.prodottoCRM.spreadLuceLordoPerdite
              : ""
          )
          ?.replace(
            /@spreadF1/g,
            products?.[0]?.content?.prodottoCRM?.spreadF1
              ? products[0].content.prodottoCRM.spreadF1
              : ""
          )
          ?.replace(
            /@spreadF2/g,
            products?.[0]?.content?.prodottoCRM?.spreadF2
              ? products[0].content.prodottoCRM.spreadF2
              : ""
          )
          ?.replace(
            /@spreadF3/g,
            products?.[0]?.content?.prodottoCRM?.spreadF3
              ? products[0].content.prodottoCRM.spreadF3
              : ""
          )
          ?.replace(
            /@quotaPvolkWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolkWh
              ? products[0].content.prodottoCRM.quotaPvolkWh
              : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF1kWh
              ? products[0].content.prodottoCRM.quotaPvolF1kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF2kWh
              ? products[0].content.prodottoCRM.quotaPvolF2kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF3kWh
              ? products[0].content.prodottoCRM.quotaPvolF3kWh
              : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfakWh
              ? products[0].content.prodottoCRM.quotaAlfakWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF1kWh
              ? products[0].content.prodottoCRM.quotaAlfaF1kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF2kWh
              ? products[0].content.prodottoCRM.quotaAlfaF2kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF3kWh
              ? products[0].content.prodottoCRM.quotaAlfaF3kWh
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF1
              ? products[0].content.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF23
              ? products[0].content.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? products[0].content.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
        hr: element?.content?.hrSw1Ele?.value,
        isBold: element?.content?.isBold1Ele?.value,
        isTooltip: element?.content?.isTooltip1Ele?.value,
        tooltip: element?.content?.isTooltip1Ele?.content?.carattTooltipSw1Ele?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const gasFeaturesSw11 = useMemo(() => {
    const features = products?.[0]?.content?.switchTitle1?.content?.caratt1Gas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Gas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            products?.[0]?.content?.prodottoCRM?.componentePrezzoFisso
              ? products[0].content.prodottoCRM.componentePrezzoFisso
              : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolSmc
              ? products[0].content.prodottoCRM.quotaPvolSmc
              : ""
          )
          ?.replace(
            /@spreadGas/g,
            products?.[0]?.content?.prodottoCRM?.spreadGas
              ? products[0].content.prodottoCRM.spreadGas
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? products[0].content.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          ),
        hr: element?.content?.hrSw1Gas?.value,
        isBold: element?.content?.isBold1Gas?.value,
        isTooltip: element?.content?.isTooltip1Gas?.value,
        tooltip: element?.content?.isTooltip1Gas?.content?.carattTooltipSw1Gas?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  return (
    <div className="card-mono d-flex flex-column align-items-center">
      <div
        className={classNames("card-config d-none mx-3 mx-md-0", {
          "d-block": chosenProd === 0,
        })}
      >
        {products[0]?.content?.offertaLabel?.value && (
          <div className="card-config__cheap">{products[0].content.offertaLabel.value}</div>
        )}
        <div className="text-center">
          <div className="card-config__title px-1">{products[0]?.value}</div>
        </div>
        <div className="card-config__sez">
          {/* Render dei primi due elementi della lista caratteristiche luce */}
          <div className="card-config__feature d-flex justify-content-between py-2 ml-2 mr-4">
            <div className="card-config__feature-icon d-flex ml-2">
              <img className="" src={LuceIcon} alt="icona" />
            </div>
            <div className="card-config__feature-text mr-3 w-50">
              <span>{eleFeaturesSw11[0]?.label}</span>
              <div className="second mt-1">{eleFeaturesSw11[1]?.label}</div>
            </div>
            <div className="card-config__feature-price w-50">
              {eleFeaturesSw11[0]?.value}
              <span>
                €/kWh
                <sup>(1)</sup>
              </span>
              <div className="second mt-1">{eleFeaturesSw11[1]?.value}</div>
            </div>
          </div>
          {/* Render dei rimanenti elementi della lista */}
          {eleFeaturesSw11?.length > 0 &&
            eleFeaturesSw11.map(
              (feature, key) =>
                key > 1 && (
                  <div
                    className="card-offer__feature d-flex justify-content-between py-2 w-100"
                    key={key}
                  >
                    <div className="card-config__feature-text-other d-flex mr-4 ml-3">
                      <span>{feature?.label}</span>
                    </div>
                    <div className="card-offer__feature-price mr-4">{feature?.value}</div>
                  </div>
                )
            )}
        </div>

        <div className="card-config__sez">
          {/* Render dei primi due elementi della lista caratteristiche Gas */}
          <div className="card-config__feature d-flex justify-content-between py-2 ml-2 mr-4">
            <div className="card-config__feature-icon d-flex ml-2">
              <img className="" src={GasIcon} alt="icona" />
            </div>
            <div className="card-config__feature-text mr-3 w-50">
              <span>{gasFeaturesSw11[0]?.label}</span>
              <div className="second mt-1">{gasFeaturesSw11[1]?.label}</div>
            </div>
            <div className="card-config__feature-price w-50">
              {gasFeaturesSw11[0]?.value}
              <span>
                €/smc
                <sup>(2)</sup>
              </span>
              <div className="second mt-1">{gasFeaturesSw11[1]?.value}</div>
            </div>
          </div>
          {/* Render dei rimanenti elementi della lista */}
          {gasFeaturesSw11?.length > 0 &&
            gasFeaturesSw11.map(
              (feature, key) =>
                key > 1 && (
                  <div
                    className="card-offer__feature d-flex justify-content-between py-2 w-100"
                    key={key}
                  >
                    <div className="card-config__feature-text-other d-flex mr-4 ml-3">
                      <span>{feature?.label}</span>
                    </div>
                    <div className="card-offer__feature-price mr-4">{feature?.value}</div>
                  </div>
                )
            )}
        </div>
        <div style={{ display: "inline-block" }} className="d-flex justify-content-center">
          <div className="slide-header__link pt-4 text-center text-md-left mt-4">
            <button
              style={{ fontSize: "14px", height: "47px" }}
              title={ctaList[0]?.value}
              className="cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 primary"
              onClick={() =>
                ctaFlussoAction(offertaTitle?.[0]?.content?.offertaCtaLink?.value, false)
              }
            >
              {ctaList[0]?.value}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurazioneCard;
