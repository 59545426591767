import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Switch from "./switch";
import NavTabs from "./nav-tabs";
import HeaderCardFeatures from "./header-card-features";

const ComparatoreCardMono = ({
  products,
  chosenProd,
  setChosenProd,
  selectActive,
  setSelectActive,
  commodity,
  selectedCard,
  setSelectedCard,
}) => {
  const toggleActive = useCallback(() => setSelectActive((value) => !value), [setSelectActive]);

  const eleFeaturesSw11 = useMemo(() => {
    const features = products?.[0]?.content?.switchTitle1?.content?.caratt1Ele
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Ele?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoEnergia
              ? products[0].content.prodottoCRM.corrispettivoEnergia
              : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF1
              ? products[0].content.prodottoCRM.corrispettivoF1
              : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF2
              ? products[0].content.prodottoCRM.corrispettivoF2
              : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoF3
              ? products[0].content.prodottoCRM.corrispettivoF3
              : ""
          )
          ?.replace(
            /@spreadLuce/g,
            products?.[0]?.content?.prodottoCRM?.spreadLuce
              ? products[0].content.prodottoCRM.spreadLuce
              : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            products?.[0]?.content?.prodottoCRM?.spreadLuceLordoPerdite
              ? products[0].content.prodottoCRM.spreadLuceLordoPerdite
              : ""
          )
          ?.replace(
            /@spreadF1/g,
            products?.[0]?.content?.prodottoCRM?.spreadF1
              ? products[0].content.prodottoCRM.spreadF1
              : ""
          )
          ?.replace(
            /@spreadF2/g,
            products?.[0]?.content?.prodottoCRM?.spreadF2
              ? products[0].content.prodottoCRM.spreadF2
              : ""
          )
          ?.replace(
            /@spreadF3/g,
            products?.[0]?.content?.prodottoCRM?.spreadF3
              ? products[0].content.prodottoCRM.spreadF3
              : ""
          )
          ?.replace(
            /@quotaPvolkWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolkWh
              ? products[0].content.prodottoCRM.quotaPvolkWh
              : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF1kWh
              ? products[0].content.prodottoCRM.quotaPvolF1kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF2kWh
              ? products[0].content.prodottoCRM.quotaPvolF2kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolF3kWh
              ? products[0].content.prodottoCRM.quotaPvolF3kWh
              : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfakWh
              ? products[0].content.prodottoCRM.quotaAlfakWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF1kWh
              ? products[0].content.prodottoCRM.quotaAlfaF1kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF2kWh
              ? products[0].content.prodottoCRM.quotaAlfaF2kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            products?.[0]?.content?.prodottoCRM?.quotaAlfaF3kWh
              ? products[0].content.prodottoCRM.quotaAlfaF3kWh
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF1
              ? products[0].content.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF23
              ? products[0].content.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? products[0].content.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
        hr: element?.content?.hrSw1Ele?.value,
        isBold: element?.content?.isBold1Ele?.value,
        isTooltip: element?.content?.isTooltip1Ele?.value,
        tooltip: element?.content?.isTooltip1Ele?.content?.carattTooltipSw1Ele?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const gasFeaturesSw11 = useMemo(() => {
    const features = products?.[0]?.content?.switchTitle1?.content?.caratt1Gas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Gas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            products?.[0]?.content?.prodottoCRM?.componentePrezzoFisso
              ? products[0].content.prodottoCRM.componentePrezzoFisso
              : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            products?.[0]?.content?.prodottoCRM?.quotaPvolSmc
              ? products[0].content.prodottoCRM.quotaPvolSmc
              : ""
          )
          ?.replace(
            /@spreadGas/g,
            products?.[0]?.content?.prodottoCRM?.spreadGas
              ? products[0].content.prodottoCRM.spreadGas
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? products[0].content.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          ),
        hr: element?.content?.hrSw1Gas?.value,
        isBold: element?.content?.isBold1Gas?.value,
        isTooltip: element?.content?.isTooltip1Gas?.value,
        tooltip: element?.content?.isTooltip1Gas?.content?.carattTooltipSw1Gas?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const eleFeaturesSw12 = useMemo(() => {
    const features =
      products?.[0]?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Ele
        ?.map((element) => ({
          label: element?.value,
          value: element?.content?.valueField2Ele?.value
            ?.replace(
              /@corrispettivoEnergia/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoEnergia
                ? products[0].content.prodottoCRM.corrispettivoEnergia
                : ""
            )
            ?.replace(
              /@corrispettivoF1/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoF1
                ? products[0].content.prodottoCRM.corrispettivoF1
                : ""
            )
            ?.replace(
              /@corrispettivoF2/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoF2
                ? products[0].content.prodottoCRM.corrispettivoF2
                : ""
            )
            ?.replace(
              /@corrispettivoF3/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoF3
                ? products[0].content.prodottoCRM.corrispettivoF3
                : ""
            )
            ?.replace(
              /@spreadLuce/g,
              products?.[0]?.content?.prodottoCRM?.spreadLuce
                ? products[0].content.prodottoCRM.spreadLuce
                : ""
            )
            ?.replace(
              /@consumoLuceLordoPerdite/g,
              products?.[0]?.content?.prodottoCRM?.spreadLuceLordoPerdite
                ? products[0].content.prodottoCRM.spreadLuceLordoPerdite
                : ""
            )
            ?.replace(
              /@spreadF1/g,
              products?.[0]?.content?.prodottoCRM?.spreadF1
                ? products[0].content.prodottoCRM.spreadF1
                : ""
            )
            ?.replace(
              /@spreadF2/g,
              products?.[0]?.content?.prodottoCRM?.spreadF2
                ? products[0].content.prodottoCRM.spreadF2
                : ""
            )
            ?.replace(
              /@spreadF3/g,
              products?.[0]?.content?.prodottoCRM?.spreadF3
                ? products[0].content.prodottoCRM.spreadF3
                : ""
            )
            ?.replace(
              /@quotaPvolkWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaPvolkWh
                ? products[0].content.prodottoCRM.quotaPvolkWh
                : ""
            )
            ?.replace(
              /@quotaPvolF1kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaPvolF1kWh
                ? products[0].content.prodottoCRM.quotaPvolF1kWh
                : ""
            )
            ?.replace(
              /@quotaPvolF2kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaPvolF2kWh
                ? products[0].content.prodottoCRM.quotaPvolF2kWh
                : ""
            )
            ?.replace(
              /@quotaPvolF3kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaPvolF3kWh
                ? products[0].content.prodottoCRM.quotaPvolF3kWh
                : ""
            )
            ?.replace(
              /@quotaAlfakWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaAlfakWh
                ? products[0].content.prodottoCRM.quotaAlfakWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF1kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaAlfaF1kWh
                ? products[0].content.prodottoCRM.quotaAlfaF1kWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF2kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaAlfaF2kWh
                ? products[0].content.prodottoCRM.quotaAlfaF2kWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF3kWh/g,
              products?.[0]?.content?.prodottoCRM?.quotaAlfaF3kWh
                ? products[0].content.prodottoCRM.quotaAlfaF3kWh
                : ""
            )
            ?.replace(
              /@corrispettivoBiorariaF1/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF1
                ? products[0].content.prodottoCRM.corrispettivoBiorariaF1
                : ""
            )
            ?.replace(
              /@corrispettivoBiorariaF23/g,
              products?.[0]?.content?.prodottoCRM?.corrispettivoBiorariaF23
                ? products[0].content.prodottoCRM.corrispettivoBiorariaF23
                : ""
            )
            ?.replace(
              /@quotaCommFissaDomesticoLuce/g,
              products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
                ? products[0].content.prodottoCRM.quotaCommFissaDomesticoLuce
                : ""
            )
            ?.replace(
              /@quotaCommVariabileDomesticoLuce/g,
              products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
                ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoLuce
                : ""
            ),
          hr: element?.content?.hrSw2Ele?.value,
          isBold: element?.content?.isBold2Ele?.value,
          isTooltip: element?.content?.isTooltip2Ele?.value,
          tooltip: element?.content?.isTooltip2Ele?.content?.carattTooltipSw2Ele?.value,
        }))
        .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const gasFeaturesSw12 = useMemo(() => {
    const features =
      products?.[0]?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Gas
        ?.map((element) => ({
          label: element?.value,
          value: element?.content?.valueField2Gas?.value
            ?.replace(
              /@componentePrezzoFisso/g,
              products?.[0]?.content?.prodottoCRM?.componentePrezzoFisso
                ? products[0].content.prodottoCRM.componentePrezzoFisso
                : ""
            )
            ?.replace(
              /@quotaPvolSmc/g,
              products?.[0]?.content?.prodottoCRM?.quotaPvolSmc
                ? products[0].content.prodottoCRM.quotaPvolSmc
                : ""
            )
            ?.replace(
              /@spreadGas/g,
              products?.[0]?.content?.prodottoCRM?.spreadGas
                ? products[0].content.prodottoCRM.spreadGas
                : ""
            )
            ?.replace(
              /@quotaCommFissaDomesticoGas/g,
              products?.[0]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
                ? products[0].content.prodottoCRM.quotaCommFissaDomesticoGas
                : ""
            )
            ?.replace(
              /@quotaCommVariabileDomesticoGas/g,
              products?.[0]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
                ? products[0].content.prodottoCRM.quotaCommVariabileDomesticoGas
                : ""
            ),
          hr: element?.content?.hrSw2Gas?.value,
          isBold: element?.content?.isBold2Gas?.value,
          isTooltip: element?.content?.isTooltip2Gas?.value,
          tooltip: element?.content?.isTooltip2Gas?.content?.carattTooltipSw2Gas?.value,
        }))
        .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const eleFeaturesSw21 = useMemo(() => {
    const features = products?.[1]?.content?.switchTitle1?.content?.caratt1Ele
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Ele?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoEnergia
              ? products[1].content.prodottoCRM.corrispettivoEnergia
              : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoF1
              ? products[1].content.prodottoCRM.corrispettivoF1
              : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoF2
              ? products[1].content.prodottoCRM.corrispettivoF2
              : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoF3
              ? products[1].content.prodottoCRM.corrispettivoF3
              : ""
          )
          ?.replace(
            /@spreadLuce/g,
            products?.[1]?.content?.prodottoCRM?.spreadLuce
              ? products[1].content.prodottoCRM.spreadLuce
              : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            products?.[1]?.content?.prodottoCRM?.spreadLuceLordoPerdite
              ? products[1].content.prodottoCRM.spreadLuceLordoPerdite
              : ""
          )
          ?.replace(
            /@spreadF1/g,
            products?.[1]?.content?.prodottoCRM?.spreadF1
              ? products[1].content.prodottoCRM.spreadF1
              : ""
          )
          ?.replace(
            /@spreadF2/g,
            products?.[1]?.content?.prodottoCRM?.spreadF2
              ? products[1].content.prodottoCRM.spreadF2
              : ""
          )
          ?.replace(
            /@spreadF3/g,
            products?.[1]?.content?.prodottoCRM?.spreadF3
              ? products[1].content.prodottoCRM.spreadF3
              : ""
          )
          ?.replace(
            /@quotaPvolkWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaPvolkWh
              ? products[1].content.prodottoCRM.quotaPvolkWh
              : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaPvolF1kWh
              ? products[1].content.prodottoCRM.quotaPvolF1kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaPvolF2kWh
              ? products[1].content.prodottoCRM.quotaPvolF2kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaPvolF3kWh
              ? products[1].content.prodottoCRM.quotaPvolF3kWh
              : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaAlfakWh
              ? products[1].content.prodottoCRM.quotaAlfakWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaAlfaF1kWh
              ? products[1].content.prodottoCRM.quotaAlfaF1kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaAlfaF2kWh
              ? products[1].content.prodottoCRM.quotaAlfaF2kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            products?.[1]?.content?.prodottoCRM?.quotaAlfaF3kWh
              ? products[1].content.prodottoCRM.quotaAlfaF3kWh
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoBiorariaF1
              ? products[1].content.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            products?.[1]?.content?.prodottoCRM?.corrispettivoBiorariaF23
              ? products[1].content.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            products?.[1]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? products[1].content.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            products?.[1]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? products[1].content.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
        hr: element?.content?.hrSw1Ele?.value,
        isBold: element?.content?.isBold1Ele?.value,
        isTooltip: element?.content?.isTooltip1Ele?.value,
        tooltip: element?.content?.isTooltip1Ele?.content?.carattTooltipSw1Ele?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const gasFeaturesSw21 = useMemo(() => {
    const features = products?.[1]?.content?.switchTitle1?.content?.caratt1Gas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Gas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            products?.[1]?.content?.prodottoCRM?.componentePrezzoFisso
              ? products[1].content.prodottoCRM.componentePrezzoFisso
              : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            products?.[1]?.content?.prodottoCRM?.quotaPvolSmc
              ? products[1].content.prodottoCRM.quotaPvolSmc
              : ""
          )
          ?.replace(
            /@spreadGas/g,
            products?.[1]?.content?.prodottoCRM?.spreadGas
              ? products[1].content.prodottoCRM.spreadGas
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            products?.[1]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? products[1].content.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            products?.[1]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? products[1].content.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          ),
        hr: element?.content?.hrSw1Gas?.value,
        isBold: element?.content?.isBold1Gas?.value,
        isTooltip: element?.content?.isTooltip1Gas?.value,
        tooltip: element?.content?.isTooltip1Gas?.content?.carattTooltipSw1Gas?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const eleFeaturesSw22 = useMemo(() => {
    const features =
      products?.[1]?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Ele
        ?.map((element) => ({
          label: element?.value,
          value: element?.content?.valueField2Ele?.value
            ?.replace(
              /@corrispettivoEnergia/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoEnergia
                ? products[1].content.prodottoCRM.corrispettivoEnergia
                : ""
            )
            ?.replace(
              /@corrispettivoF1/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoF1
                ? products[1].content.prodottoCRM.corrispettivoF1
                : ""
            )
            ?.replace(
              /@corrispettivoF2/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoF2
                ? products[1].content.prodottoCRM.corrispettivoF2
                : ""
            )
            ?.replace(
              /@corrispettivoF3/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoF3
                ? products[1].content.prodottoCRM.corrispettivoF3
                : ""
            )
            ?.replace(
              /@spreadLuce/g,
              products?.[1]?.content?.prodottoCRM?.spreadLuce
                ? products[1].content.prodottoCRM.spreadLuce
                : ""
            )
            ?.replace(
              /@consumoLuceLordoPerdite/g,
              products?.[1]?.content?.prodottoCRM?.spreadLuceLordoPerdite
                ? products[1].content.prodottoCRM.spreadLuceLordoPerdite
                : ""
            )
            ?.replace(
              /@spreadF1/g,
              products?.[1]?.content?.prodottoCRM?.spreadF1
                ? products[1].content.prodottoCRM.spreadF1
                : ""
            )
            ?.replace(
              /@spreadF2/g,
              products?.[1]?.content?.prodottoCRM?.spreadF2
                ? products[1].content.prodottoCRM.spreadF2
                : ""
            )
            ?.replace(
              /@spreadF3/g,
              products?.[1]?.content?.prodottoCRM?.spreadF3
                ? products[1].content.prodottoCRM.spreadF3
                : ""
            )
            ?.replace(
              /@quotaPvolkWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaPvolkWh
                ? products[1].content.prodottoCRM.quotaPvolkWh
                : ""
            )
            ?.replace(
              /@quotaPvolF1kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaPvolF1kWh
                ? products[1].content.prodottoCRM.quotaPvolF1kWh
                : ""
            )
            ?.replace(
              /@quotaPvolF2kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaPvolF2kWh
                ? products[1].content.prodottoCRM.quotaPvolF2kWh
                : ""
            )
            ?.replace(
              /@quotaPvolF3kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaPvolF3kWh
                ? products[1].content.prodottoCRM.quotaPvolF3kWh
                : ""
            )
            ?.replace(
              /@quotaAlfakWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaAlfakWh
                ? products[1].content.prodottoCRM.quotaAlfakWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF1kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaAlfaF1kWh
                ? products[1].content.prodottoCRM.quotaAlfaF1kWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF2kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaAlfaF2kWh
                ? products[1].content.prodottoCRM.quotaAlfaF2kWh
                : ""
            )
            ?.replace(
              /@quotaAlfaF3kWh/g,
              products?.[1]?.content?.prodottoCRM?.quotaAlfaF3kWh
                ? products[1].content.prodottoCRM.quotaAlfaF3kWh
                : ""
            )
            ?.replace(
              /@corrispettivoBiorariaF1/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoBiorariaF1
                ? products[1].content.prodottoCRM.corrispettivoBiorariaF1
                : ""
            )
            ?.replace(
              /@corrispettivoBiorariaF23/g,
              products?.[1]?.content?.prodottoCRM?.corrispettivoBiorariaF23
                ? products[1].content.prodottoCRM.corrispettivoBiorariaF23
                : ""
            )
            ?.replace(
              /@quotaCommFissaDomesticoLuce/g,
              products?.[1]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
                ? products[1].content.prodottoCRM.quotaCommFissaDomesticoLuce
                : ""
            )
            ?.replace(
              /@quotaCommVariabileDomesticoLuce/g,
              products?.[1]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
                ? products[1].content.prodottoCRM.quotaCommVariabileDomesticoLuce
                : ""
            ),
          hr: element?.content?.hrSw2Ele?.value,
          isBold: element?.content?.isBold2Ele?.value,
          isTooltip: element?.content?.isTooltip2Ele?.value,
          tooltip: element?.content?.isTooltip2Ele?.content?.carattTooltipSw2Ele?.value,
        }))
        .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  const gasFeaturesSw22 = useMemo(() => {
    const features =
      products?.[1]?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Gas
        ?.map((element) => ({
          label: element?.value,
          value: element?.content?.valueField2Gas?.value
            ?.replace(
              /@componentePrezzoFisso/g,
              products?.[1]?.content?.prodottoCRM?.componentePrezzoFisso
                ? products[1].content.prodottoCRM.componentePrezzoFisso
                : ""
            )
            ?.replace(
              /@quotaPvolSmc/g,
              products?.[1]?.content?.prodottoCRM?.quotaPvolSmc
                ? products[1].content.prodottoCRM.quotaPvolSmc
                : ""
            )
            ?.replace(
              /@spreadGas/g,
              products?.[1]?.content?.prodottoCRM?.spreadGas
                ? products[1].content.prodottoCRM.spreadGas
                : ""
            )
            ?.replace(
              /@quotaCommFissaDomesticoGas/g,
              products?.[1]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
                ? products[1].content.prodottoCRM.quotaCommFissaDomesticoGas
                : ""
            )
            ?.replace(
              /@quotaCommVariabileDomesticoGas/g,
              products?.[1]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
                ? products[1].content.prodottoCRM.quotaCommVariabileDomesticoGas
                : ""
            ),
          hr: element?.content?.hrSw2Gas?.value,
          isBold: element?.content?.isBold2Gas?.value,
          isTooltip: element?.content?.isTooltip2Gas?.value,
          tooltip: element?.content?.isTooltip2Gas?.content?.carattTooltipSw2Gas?.value,
        }))
        .filter((item) => !item.value.includes("@"));

    return features;
  }, [products]);

  return (
    <div className="card-mono d-flex flex-column align-items-center">
      <NavTabs
        tabs={products?.map((item) => item?.content?.offertaSwitchButtonTitle)}
        selectedTab={chosenProd}
        setTab={setChosenProd}
        className="d-xl-none pb-3"
      />
      {products?.map((prod, i) => (
        <div
          className={classNames("card-offer d-none mx-3 mx-md-0", {
            "d-block": chosenProd === i,
          })}
          key={i}
        >
          {prod?.content?.offertaLabel?.value && (
            <div className="card-offer__cheap">{prod.content.offertaLabel.value}</div>
          )}
          <div className="text-center">
            {prod?.content?.offertaIcona?.node && (
              <GatsbyImage
                image={getImage(prod.content.offertaIcona.node)}
                alt="Offerta 12 mesi"
                className="card-offer__img"
              />
            )}
            <div className="card-offer__title">{prod?.value}</div>
            <div className="sticky-footer__title px-lg-2">
              Fino al {products?.[0]?.content?.prodottoCRM?.dataFineValidita}
            </div>
          </div>
          {products.length >= 2 && (
            <div className="card-offer__sez d-none d-xl-block">
              <NavTabs
                tabs={products?.map((item) => item?.content?.offertaSwitchButtonTitle)}
                selectedTab={chosenProd}
                setTab={setChosenProd}
              />
            </div>
          )}
          {commodity?.length > 1 && (
            <div className="card-offer__sez">
              <NavTabs tabs={commodity} selectedTab={selectedCard} setTab={setSelectedCard} />
            </div>
          )}
          {commodity?.[selectedCard].content.tipoCommodity?.value?.[0] !== "gas" &&
            prod?.content?.switchPresent?.value && (
              <div className="card-offer__sez">
                {prod?.content?.switchPresent?.value && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className={classNames("info d-flex align-items-center", {
                        "info--active": selectActive,
                      })}
                    >
                      <div className="info-label-small">{prod?.content?.switchTitle1?.value}</div>
                      <div
                        className={`card-offer__tooltip${
                          selectActive ? " active" : ""
                        } d-none d-md-block`}
                      >
                        <span className="card-offer__tooltip-label">i</span>
                        <div className="card-offer__tooltip-text">
                          {prod?.content?.switchTitle1?.content?.firstTooltip?.value}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Switch active={!selectActive} toggleFun={toggleActive} />
                    </div>
                    <div
                      className={classNames("info d-flex align-items-center", {
                        "info--active": !selectActive,
                      })}
                    >
                      <div
                        className={`card-offer__tooltip${
                          selectActive ? "" : " active"
                        } d-none d-md-block`}
                      >
                        <span className="card-offer__tooltip-label">i</span>
                        <div className="card-offer__tooltip-text">
                          {
                            prod?.content?.switchPresent?.content?.switchTitle2?.content
                              ?.secondTooltip?.value
                          }
                        </div>
                      </div>
                      <div className="info-label-small text-right">
                        {prod?.content?.switchPresent?.content?.switchTitle2?.value}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          {/*Caratteristica esclusiva della comodity */}
          {commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]?.value && (
            <div className="card-offer__sez card-offer__sez d-flex align-items-center d-flex justify-content-between">
              <div className="card-offer__feature-text card-offer__feature-text--bold d-flex align-items-end">
                {commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]?.value}

                {commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]?.content
                  ?.tooltipCaratteristicaEclusiva?.value && (
                  <div className="card-offer__tooltip active d-none d-md-block">
                    <span className="card-offer__tooltip-label">i</span>
                    <div className="card-offer__tooltip-text">
                      {
                        commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]
                          ?.content?.tooltipCaratteristicaEclusiva?.value
                      }
                    </div>
                  </div>
                )}
              </div>

              <div className="card-offer__feature-price">
                {
                  commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]?.content
                    ?.valoreCaratteristicaEsclusiva?.value
                }
              </div>
            </div>
          )}
          <div className="card-offer__sez">
            {chosenProd === 0 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "luce") &&
              selectActive && (
                <HeaderCardFeatures
                  features={eleFeaturesSw11}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 0 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "gas") &&
              selectActive && (
                <HeaderCardFeatures
                  features={gasFeaturesSw11}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 0 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "luce") &&
              !selectActive && (
                <HeaderCardFeatures
                  features={eleFeaturesSw12}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 0 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "gas") &&
              !selectActive && (
                <HeaderCardFeatures
                  features={gasFeaturesSw12}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 1 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "luce") &&
              selectActive && (
                <HeaderCardFeatures
                  features={eleFeaturesSw21}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 1 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "gas") &&
              selectActive && (
                <HeaderCardFeatures
                  features={gasFeaturesSw21}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 1 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "luce") &&
              !selectActive && (
                <HeaderCardFeatures
                  features={eleFeaturesSw22}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
            {chosenProd === 1 &&
              (commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual" ||
                commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "gas") &&
              !selectActive && (
                <HeaderCardFeatures
                  features={gasFeaturesSw22}
                  dualCommodity={
                    commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] === "dual"
                  }
                />
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComparatoreCardMono;
